<template>
    <el-form-item :label="$t('calendar.services')">
        <ServicesDropdown ref="servicesDropdown" class="w-full" :withoutQuerySearch="true" @change="selectChanged" />
    </el-form-item>
</template>
<script>
export default {
    components: {
        ServicesDropdown: () => import(/* webpackChunkName: "ServicesDropdown" */ '@/components/ServicesDropdown'),
    },

    props: {
        modalVisible: {
            type:    Boolean,
            default: false,
        },
    },

    watch: {
        modalVisible() {
            this.selectedServices = [];
            this.$refs.servicesDropdown.resetSelection();
            this.$emit('change', []);
        },
    },

    methods: {
        selectChanged(services) {
            this.$emit('change', services);
        },
    },
};
</script>
